<template>
  <div id="app" class="wrapper">
    <div class="mobile-menu"  v-if="navMobile">
      <ul class="nav-list-mobile">
        <li>
          <nuxt-link to="/elektrische-auto">
            Home
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/vergelijk/autokosten" :class="$route.name == 'vergelijk-autokosten-carid-cars' ? 'nuxt-link-exact-active':''">
            Autokosten
          </nuxt-link>
        </li>
        <li>
          <nuxt-link to="/vergelijk/ev-prestaties" :class="$route.name == 'vergelijk-autokosten-ev-prestaties' ? 'nuxt-link-exact-active':''">
            EV prestaties
          </nuxt-link>
        </li>
        <li>
           <nuxt-link to="/ev-blog" :class="$route.name == 'ev-blog-artikel' ? 'nuxt-link-exact-active':''">
             EV Blog
           </nuxt-link>
         </li>
        <li v-if="false" style="padding-top: 9px; display: flex; justify-content: center;">
          <div>
            <div class="searchbox searchbox-nav w-100">
              <div class="input-group stylish-input-group">
                <input type="text" placeholder="Zoeken" class="form-control" v-on:keyup="checkIfEnter" name="zoeken" v-model="searchQuery">
                <span class="input-group-addon">
                  <div class="cursor-pointer nav__search" name="zoeken" @click="carSearch()">
                      <i class="mdi mdi-magnify" style="font-size: 16px;"></i>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </li>
        <!--<li>
          <a href="https://www.instagram.com/whattherange/" target="_blank">
            <i class="mdi mdi-instagram"></i>
          </a>
        </li> -->
      </ul>
    </div>
    <div id="navigation">
      <div class="container">
        <div class="row" style="max-height: 100%;z-index: 9999;position: relative;">
          <div class="col-lg-4">
            <div class="logo__container">
              <nuxt-link to="/" class="no-decoration d-flex align-items-center">
                <h1 class="mb-0 logo_wtr">WhatThe<span>Range</span></h1>
              </nuxt-link>
              <div class="powered_by powered_by--top">
                <p class="powered_by__text">by </p>
                <img class="powered_by__logo" src="../assets/autodisk_logo.png" alt="AutoDisk">
              </div>
            </div>


            <i class="mdi mdi-menu menu-mobile-icon" v-if="!navMobile" @click="navMobile = !navMobile"></i>
            <i class="mdi mdi-close menu-mobile-icon" v-else @click="navMobile = !navMobile"></i>
          </div>
          <div class="col-md-8 d-flex align-items-content justify-content-end">

            <ul class="nav-list">
              <transition name="slide-fade">
                <li v-if="$route.name == 'elektrische-auto-filter'" style="margin-left:-100px; padding-top: 9px;">
                  <div>
                    <div class="searchbox searchbox-nav">
                      <div class="input-group stylish-input-group">
                        <input type="text" placeholder="Zoeken" class="form-control" v-on:keyup="checkIfEnter" name="zoeken" v-model="searchQuery">
                        <span class="input-group-addon">
                          <div class="cursor-pointer nav__search" name="zoeken" @click="carSearch()">
                              <i class="mdi mdi-magnify" style="font-size: 16px;"></i>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </transition>
              <li>
                <nuxt-link to="/elektrische-auto" :class="$route.path.includes('elektrische-auto') ? 'nuxt-link-exact-active':''">
                  Home
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/vergelijk/autokosten" :class="$route.name == 'vergelijk-autokosten-carid-cars' ? 'nuxt-link-exact-active':''">
                  Autokosten
                </nuxt-link>
              </li>

              <li>
                <nuxt-link to="/vergelijk/ev-prestaties/actieradius" :class="$route.path.includes('ev-prestaties') ? 'nuxt-link-exact-active':''">
                  EV prestaties
                </nuxt-link>
              </li>

              <li>
                <nuxt-link to="/ev-blog" :class="$route.name == 'ev-blog-artikel' ? 'nuxt-link-exact-active':''">
                  EV Blog
                </nuxt-link>
              </li>
              <!--<li>
                <a href="https://www.instagram.com/whattherange/" target="_blank">
                  <i class="mdi mdi-instagram"></i>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <transition name="slide-fade">
        <div v-if="scrollY > 800 && navMobile === false && $route.name == 'elektrische-auto-filter'" style="background-color: #effbf4;" class="py-3 row d-md-none d-flex justify-content-center">
          <div class="w-75">
            <div class="searchbox searchbox-nav w-100">
              <div class="input-group stylish-input-group">
                <input type="text" placeholder="Zoeken" class="form-control" v-on:keyup="checkIfEnter" name="zoeken" v-model="searchQuery">
                <span class="input-group-addon">
                  <div class="cursor-pointer nav__search" name="zoeken" @click="carSearch()">
                      <i class="mdi mdi-magnify ml-0 pl-0" style="font-size: 16px;"></i>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        </transition>
      </div>
    </div>
    <div id="top" style="padding-bottom: 70px;"></div>
    <!-- application -->
    <nuxt/>

    <ScrollToTop el="#top" :offset="-10" :show-from="300" />
    <!-- Footer -->
    <Footer />
    <!-- END Footer -->

  </div>
</template>

<script type="text/javascript">
import ScrollToTop from '@/components/ScrollToTop'
import Footer from '@/components/Footer'

export default {
  components:{
    Footer,
    ScrollToTop
  },
  name:'WhatTheRange',
  data(){
    return {
      searchQuery: '',
      navMobile  : false,
      scrollY: 0,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler()
  },
  destroyed(){
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    checkIfEnter(e){
      if (e.keyCode == 13) {
        this.carSearch();
      }
    },
    carSearch(){
      this.$router.push({ name: 'elektrische-auto-filter', query: { search: this.searchQuery }, hash: '#searchbox' });

      this.searchQuery = '';

      // this.$router.push({ path: 'elektrische-auto-filter', query: { search: this.searchQuery } })
    },
    scrollHandler () {
      this.scrollY = window.scrollY
    }
  },
  watch:{
    '$route.params': function () {
        this.navMobile = false
      }
  }

}
</script>


<style media="screen" lang="scss">
.vergelijk_knop{
  position: absolute;
  z-index: 60;
  background-color: #2ecc71;
  color: hsl(110, 70%, 99%);
  color: white;
  box-shadow: 0 1px 4px hsla(0, 0%, 0%, 0.3);
  padding: 5px;
  letter-spacing: 1px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
}



.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #FFFFFF;

}

.custom-checkbox input:checked + .checkmark {
  background-color: #28a745;
  border: 3px solid #fff;
}

.nav__search{
  width: 35px !important;
  height: 35px !important;
}
.mobile-menu{
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  background: #EFFBF4;
  top: 70px;
  z-index: 10000000;
  animation: 300ms linear 0s slide;
}
@keyframes slide {
  from { right: -100%; }
  to {
    right: 0;
  }
}

.searchbox input {
  border: 1px solid #f1f1f1;
  box-shadow: inherit;
  color: #232323;
  min-height: 60px;
  outline: none;
  padding-left: 20px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;

  font-size: 14px;
}

.menu-mobile-icon{
  display: none;
  float: right;
  position: absolute;
  right: 20px;
  top: 18px;
  font-size: 24px;
}

ul.nav-list-mobile {
  padding:0;
  text-align:center;
    // float: right;
    list-style-type: none;
    li {
        // display: inline-block;
        padding: 24px;
        // padding-left: 0px;
        padding-right: 0px;
        a {
          color: #666 !important;
          font-weight: 600;
          font-family: 'Poppins', sans-serif;

        }
        .nuxt-link-exact-active{
          color: #fff !important;
          background: #2ecc71;
          border-radius: 30px;
          padding: 10px 20px;
          text-decoration: none;
        }
    }
}

#navigation {
  background: #eefbf4;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 999999999;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  h1 {
    color: #2ecc71;
    margin-top: 15px;

    span {
      color: #666666;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      color: #2ecc71;
      margin-top: 15px;
    }
  }




  ul.nav-list {
      float: right;
      list-style-type: none;
      li {
          display: inline-block;
          padding: 24px;
          // padding-top: 18px;
          // padding-left: 0px;
          padding-right: 0px;
          a {
            color: #666 !important;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;

          }
          .nuxt-link-exact-active{
            color: #fff !important;
            background: #2ecc71;
                border-radius: 30px;
                    padding: 10px 20px;
                    text-decoration: none;
          }
      }
  }

  .shape{
    position: absolute;
    bottom: -1px;
    transform: rotateX(180deg);
    top: -30px;
  }
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
