<template>
  <div class="container">
    <h1 v-if="error.statusCode === 404">Pagina niet gevonden</h1>
    <h1 v-else>WhatTheOops, er ging iets fout!</h1>
    <nuxt-link to="/elektrische-auto">Ga terug naar huis</nuxt-link>
  </div>
</template>

<script>
export default {
  props: ['error'],
  layout: 'default' // you can set a custom layout for the error page
}
</script>
