<template lang="html">
  <svg class="section-shape" viewBox="0 23 500 32" preserveAspectRatio="none">
    <path d="M-237.97,103.13 C201.53,-102.13 318.14,183.05 741.62,-36.02 L655.95,547.25 L-381.89,482.11 Z" stroke="none" fill="#fff"></path>
  </svg>
</template>

<script>
export default {
}
</script>
