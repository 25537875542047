import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _52eda372 = () => interopDefault(import('../pages/vergelijk/autokosten/index.vue' /* webpackChunkName: "pages/vergelijk/autokosten/index" */))
const _3ea31233 = () => interopDefault(import('../pages/vergelijk/specificaties/index.vue' /* webpackChunkName: "pages/vergelijk/specificaties/index" */))
const _76f689e6 = () => interopDefault(import('../pages/vergelijk/vergelijker/index.vue' /* webpackChunkName: "pages/vergelijk/vergelijker/index" */))
const _3eb4da6e = () => interopDefault(import('../pages/ev-blog/artikel/_index.vue' /* webpackChunkName: "pages/ev-blog/artikel/_index" */))
const _467d9dfc = () => interopDefault(import('../pages/vergelijk/ev-prestaties/_filter.vue' /* webpackChunkName: "pages/vergelijk/ev-prestaties/_filter" */))
const _3f88bda2 = () => interopDefault(import('../pages/vergelijk/autokosten/_carid/_cars.vue' /* webpackChunkName: "pages/vergelijk/autokosten/_carid/_cars" */))
const _7bbdcebb = () => interopDefault(import('../pages/elektrische-auto/_filter.vue' /* webpackChunkName: "pages/elektrische-auto/_filter" */))
const _53d66bf4 = () => interopDefault(import('../pages/ev-blog/_index.vue' /* webpackChunkName: "pages/ev-blog/_index" */))
const _5a36b1bb = () => interopDefault(import('../pages/elektrische-auto/_model/_brand/_type.vue' /* webpackChunkName: "pages/elektrische-auto/_model/_brand/_type" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/vergelijk/autokosten",
    component: _52eda372,
    name: "vergelijk-autokosten"
  }, {
    path: "/vergelijk/specificaties",
    component: _3ea31233,
    name: "vergelijk-specificaties"
  }, {
    path: "/vergelijk/vergelijker",
    component: _76f689e6,
    name: "vergelijk-vergelijker"
  }, {
    path: "/ev-blog/artikel/:index",
    component: _3eb4da6e,
    name: "ev-blog-artikel"
  }, {
    path: "/vergelijk/ev-prestaties/:filter?",
    component: _467d9dfc,
    name: "vergelijk-ev-prestaties-filter"
  }, {
    path: "/vergelijk/autokosten/:carid/:cars?",
    component: _3f88bda2,
    name: "vergelijk-autokosten-carid-cars"
  }, {
    path: "/elektrische-auto/:filter?",
    component: _7bbdcebb,
    name: "elektrische-auto-filter"
  }, {
    path: "/ev-blog/:index",
    component: _53d66bf4,
    name: "ev-blog"
  }, {
    path: "/elektrische-auto/:model?/:brand?/:type?",
    component: _5a36b1bb,
    name: "elektrische-auto-model-brand-type"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/ev-blog",
    component: _53d66bf4,
    name: "ev-blog"
  }, {
    path: "/vergelijk/vergelijker",
    component: _76f689e6,
    name: "vergelijker"
  }, {
    path: "*",
    redirect: "/"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
