// import axios from 'axios'
// import https from 'https'

export default function ({$axios, redirect, store, ctx}) {

}

// export default axios.create({
//   baseURL: process.env.baseUrl,
//
//   httpsAgent: new https.Agent({
//     rejectUnauthorized: false
//   })
//   // https:true
// })
