<template>
  <transition v-if="(scrollY >= (showFrom ? showFrom : 0))" name="slide-fade">
    <div v-scroll-to="{el: el ? el : '#top', offset: offset ? offset : 0}" class="scroll-to-top">
      <i class="gg-arrow-up"></i>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['el', 'offset', 'showFrom'],
  data () {
    return {
      scrollY: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler()
  },
  destroyed(){
    window.removeEventListener('scroll', this.scrollHandler);
  },
  methods: {
    scrollHandler () {
      this.scrollY = window.scrollY
    }
  }
}
</script>
<style lang="scss">


.gg-arrow-up {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px
}
.gg-arrow-up::after,
.gg-arrow-up::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 4px
}
.gg-arrow-up::after {
    width: 8px;
    height: 8px;
    border-top: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
    left: 7px
}
.gg-arrow-up::before {
    width: 2px;
    height: 16px;
    left: 10px;
    background: #fff;
}


  .scroll-to-top{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 9999;
    border-radius: 8px;
    background-color: darken(#2ecc71, 8%);
    padding: 10px;
    cursor: pointer;
    color: white;
    &:hover{
      background-color: darken(#2ecc71, 11%);
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
  }
</style>
