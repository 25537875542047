<template lang="html">

  <footer class="bg-colored position-relative bg-color-green">
    <div class="container">
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 pull-right">
              <p>
                <b>WhatTheRange</b> wilt graag de elektrificatie van het wagenpark stimuleren. Deze site is daarom gemaakt om je een goed beeld te geven van de actieradius en het verbruik van alle leverbare elektrische auto's. De waarden kunnen afwijken van de werkelijkheid en zijn alleen een benadering. Je kunt er geen rechten aan ontlenen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright-block position-relative">
      <Shape class="position-absolute shape"/>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-6 pull-left d-flex flex-direction-row align-items-center justify-content-between">
            <span>&copy; <a href="https://www.whattherange.com" target="_blank" rel="noopener">WhatTheRange</a> 2019 - {{year}}</span>
          <div class="ml-1 powered_by powered_by--footer">
                <p class="powered_by__text">Powered by </p>
                <img class="powered_by__logo" src="../assets/autodisk_logo.png" alt="AutoDisk">
              </div></div>

          <!-- <div class="col-lg-6 col-md-6 pull-right text-right">
              <a href="https://www.instagram.com/whattherange/" target="_blank" class="social">
                <i class="mdi mdi-instagram"></i>
              </a>
          </div> -->
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import Shape from '@/components/design/shape'
export default {
  components: {
    Shape
  },
  data(){
    return {
      year:null
    }
  },
  created(){
    this.getDate()
  },
  methods:{
    getDate(){
      var d = new Date();
      this.year = d.getFullYear();
    }
  }

}
</script>

<style media="screen" lang="scss">
.footer{
  padding-top: 60px;
  padding-bottom: 60px;
}

footer{
  z-index: 999;
  position: relative;
  .shape{
    top: -25px;
    max-height: 75px;
  }
}

img.footer-pattern{
  margin-top: -5px;
}

footer p{
  // color:#fff;
  color:#666;
}

.footer a{
  color:#2ecc71 !important;
}
.social{
  color:#666 !important
}

.copyright-block{
  background: #fff;
  padding: 0px 0 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
